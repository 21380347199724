import React, { useEffect } from 'react'
import { features } from "../constants";
import styles, { layout } from "../style";
import Button from "./Button";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
const FeatureCard = ({ icon, title, content, index, refAboutDiv, inView }) => { 

  const textDivVariants = {
    visible: { opacity: 1, y: 0, transition: { delay:0.2, duration:0.3, stiffness:120, type:"spring"  } },
    hidden: { opacity: 0.4, y: 50 }
  };

  const controls = useAnimation();
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
  <motion.div
    animate={controls}
    initial="hidden"
    variants={textDivVariants} 
    className={`w-full flex flex-row p-6 rounded-[20px] ${index !== features.length - 1 ? "mb-6" : "mb-0"} feature-card`}>
    <div className={`w-[64px] h-[64px] rounded-full ${styles.flexCenter} bg-dimBlue`}>
      <img src={icon} alt="star" className="w-[50%] h-[50%] object-contain" />
    </div>
    <div className="flex-1 flex flex-col ml-3">
      <h4 className="font-poppins font-semibold text-white text-[18px] leading-[23.4px] mb-1">
        {title}
      </h4>
      <p className="font-poppins font-normal text-dimWhite text-[16px] leading-[24px]">
        {content}
      </p>
    </div>
  </motion.div>
)};

const Business = ({handleClickScroll}) =>  { 
  
  const [refAboutDiv, inView] = useInView();
  const textDivVariants = {
    visible: { opacity: 1, x: 0, transition: { delay:0.3, duration:1, stiffness:120, type:"spring"  } },
    hidden: { opacity: 0.4, x: 50 }
  };
  
  const controls = useAnimation();
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
  <section id="about" className={layout.section}>
    <motion.div ref={refAboutDiv}
      animate={controls}
      initial="hidden"
      variants={textDivVariants} className={layout.sectionInfo}>
      <h2 className={styles.heading2}>
      Optimize your operations  <br className="sm:block hidden" /> with our 
      <span  className="text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-purple-500"> tailored</span> software solutions.
      </h2>
      <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
      We believe in delivering high-quality software solutions that meet the unique needs of our clients.
      Our process starts with understanding your business requirements and ends with a robust, scalable, and secure product.
      </p>

      <Button styles={`mt-10`}  handleClickScroll={handleClickScroll} />
    </motion.div>

    <div  className={`${layout.sectionImg} flex-col`}>
      {features.map((feature, index) => (
        <FeatureCard key={feature.id} {...feature} index={index} refAboutDiv={refAboutDiv} inView={inView}/>
      ))}
    </div>
  </section>
)};

export default Business;
