import React from "react";
import { motion } from "framer-motion";

const Button = ({ styles, handleClickScroll }) => {
  const buttonVariant = {
    hover: {
      scale: 1.05,
      transition: {
        duration: 0.3,
        repeatType: "mirror",
        repeat: 3,
      }
    }
  }
  return (
  <motion.button onClick={() => handleClickScroll("contactus")} className={`py-4 px-6 font-poppins font-medium text-[18px] text-primary  bg-gradient-to-r from-blue-500 to-purple-500 hover:from-blue-500 hover:to-pink-500 rounded-[10px] outline-none ${styles}`}
    variants={buttonVariant}
    whileHover="hover"
  >
    Contact Us
  </motion.button>
)};

export default Button;
