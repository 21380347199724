import React, { useEffect } from 'react'
import styles from "../style";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import GetStarted from './GetStarted';
const CTA = ({handleClickScroll }) => {
  const textDivVariants = {
    visible: { opacity: 1, y: 0, transition: { delay:0.4, duration:1, stiffness:120, type:"spring"  } },
    hidden: { opacity: 0.4, y: 50 }
  };
  const [refCTADiv, inView] = useInView();
  const controls = useAnimation();
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
  <div className="w-full pt-6" ref={refCTADiv} id="contactus">
  <motion.section 
    
    animate={controls}
    initial="hidden"
    variants={textDivVariants}
    className={`${styles.flexCenter} ${styles.marginY} ${styles.padding} sm:flex-row flex-col bg-black-gradient-2 rounded-[20px] box-shadow`}>
    <div className="flex-1 flex flex-col">
      <h2 className={styles.heading2}>Let’s try our service now!</h2>
      <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
      Let us help streamline your operations, enhance productivity, and future-proof your technology investments. 
      </p>
    </div>

    <div className={`${styles.flexCenter} sm:ml-10 ml-0 sm:mt-0 mt-10`}>
      <GetStarted />
    </div>
  </motion.section>
  </div>
)};

export default CTA;
