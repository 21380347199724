
import React, { useEffect } from 'react'
import styles from "../style";
import logo_usco from'../assets/logo_usco.png';
import {logo} from '../assets';
import { footerLinks, socialMedia } from "../constants";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Footer = () => {
  
  const copyWriteDivVariants = {
    visible: { opacity: 1, x: 0, transition: { delay:0.2, duration:0.3, stiffness:120, type:"spring" } },
    hidden: { opacity: 0, x: -50 }
  };
  const socialDivVariants = {
    visible: { opacity: 1, x: 0, transition: { delay:0.2, duration:0.3, stiffness:120, type:"spring"  } },
    hidden: { opacity: 0, x: 50 }
  };
 
  const controls = useAnimation();
  const [refRootDiv, inView] = useInView({ threshold: 0.2});
  useEffect(() => {
    if (inView) {
      controls.start("visible");
      
    }
  }, [controls, inView]);

  return (
  <motion.section id="footer" className={`${styles.flexCenter} ${styles.paddingY} flex-col`}>
    <div className={`${styles.flexStart} md:flex-row flex-col mb-8 w-full`}>
      <div className="flex-[1] flex flex-col justify-start mr-10">
        <img
          src={logo}
          alt="hoobank"
          className="w-[70px] h-[70px] object-contain bg-gradient-to-r from-blue-500 to-purple-500  hover:from-blue-500 hover:to-pink-500 border-2 border-gray-200"
        />
        


        
        <p className={`${styles.paragraph} mt-4 max-w-[312px]`}>
          Nurturing businesses, <br/>Simply.
        </p>
      </div>

      <motion.div className="flex-[1.5] w-full flex flex-row justify-between flex-wrap md:mt-0 mt-10"
        >
        {footerLinks.map((footerlink) => (
          <div key={footerlink.title} className={`flex flex-col ss:my-0 my-4 min-w-[150px]`}>
            <h4 className="font-poppins font-medium text-[18px] leading-[27px] text-white">
              {footerlink.title}
            </h4>
            <ul className="list-none mt-4">
              {footerlink.links.map((link, index) => (
                <li
                  key={link.name}
                  className={`font-poppins font-normal text-[16px] leading-[24px] text-dimWhite hover:text-secondary cursor-pointer ${
                    index !== footerlink.links.length - 1 ? "mb-4" : "mb-0"
                  }`}
                >
                  <a href={link.link}>{link.name}</a>
                </li>
              ))}
              {
                footerlink.title == "Partner" && (
                  <li
                  key="phone"
                  className={`font-poppins font-normal text-[16px] leading-[24px] text-dimWhite hover:text-secondary cursor-pointer 
                     mb-4 mt-4
                  `}
                >
                  <a href="tel:+971 525900347">+971 525900347</a>
                </li>
                )
              }
            </ul>
          </div>
        ))}
      </motion.div>
    </div>

    <div className="w-full flex justify-between items-center md:flex-row flex-col pt-6 border-t-[1px] border-t-[#3F3E45]"   ref={ refRootDiv }>
      <motion.p className="font-poppins font-normal text-center text-[18px] leading-[27px] text-white"
        animate={controls}
        initial="hidden"
        variants={copyWriteDivVariants}>
        Copyright Ⓒ 2022 Upspin Cloud Services. All Rights Reserved.
      </motion.p>

      <motion.div className="flex flex-row md:mt-0 mt-6"
        animate={controls}
        initial="hidden"
        variants={socialDivVariants}>
        {socialMedia.map((social, index) => (
          <img
            key={social.id}
            src={social.icon}
            alt={social.id}
            className={`w-[21px] h-[21px] object-contain cursor-pointer ${
              index !== socialMedia.length - 1 ? "mr-6" : "mr-0"
            }`}
            onClick={() => window.open(social.link)}
          />
        ))}
      </motion.div>
    </div>
  </motion.section>
)};

export default Footer;
