import React, { useRef } from 'react'
import styles from "../style";
import { arrowUp } from "../assets";
import { motion } from "framer-motion";
const GetStarted = () => {
  const constraintsRef = useRef(null)
  return (
  <motion.div ref={constraintsRef}>
    <motion.div className={`${styles.flexCenter} w-[140px] h-[140px] rounded-full bg-blue-gradient- p-[2px] cursor-pointer
    bg-gradient-to-r from-blue-500 to-purple-500`}
    drag dragConstraints={constraintsRef} dragTransition={{ bounceStiffness: 600, bounceDamping: 10 }}
    whileTap={{scale:1.1}}>
      <a href="https://wa.me/971525900347" className={`${styles.flexCenter} flex-col bg-primary w-[100%] h-[100%] rounded-full`}>
        <div className={`${styles.flexStart} flex-row`}>
          <p className="font-poppins font-medium text-[18px] leading-[23.4px]">
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-purple-500">Hire</span>
          </p>
          <img src={arrowUp} alt="arrow-up" className="w-[23px] h-[23px] object-contain" />
        </div>
        
        <p className="font-poppins font-medium text-[18px] leading-[23.4px]">
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-purple-500">Us</span>
        </p>
      </a>
    </motion.div>
  </motion.div>
)};

export default GetStarted;
