import { people01, people02, people03, facebook, instagram, linkedin, twitter, airbnb, binance, coinbase, dropbox, send, shield, star  } from "../assets";

export const navLinks = [
  {
    id: "home",
    title: "Home",
    link: "/#home"
  },
  {
    id: "about",
    title: "About",
    link: "/#about"
  },
  {
    id: "marketing",
    title: "Marketing",
    link: "/#marketing"
  },
  {
    id: "ecommerce",
    title: "Ecommerce",
    link: "/#ecommerce"
  },
  {
    id: "contactus",
    title: "Contact Us",
    link: "/#contactus"
  },
];

export const features = [
  {
    id: "feature-1",
    icon: star,
    title: "Digitalization",
    content:
      "Take your business to the next level with our expert digitization services.",
  },
  {
    id: "feature-2",
    icon: shield,
    title: "Innovative",
    content:
      "Designing a brighter tomorrow with innovative solutions.     ",
  },
  {
    id: "feature-3",
    icon: send,
    title: "Competitive",
    content:
      "Unlock your full potential and gain a decisive edge over the competition with our tailored solutions.",
  },
  {
    id: "feature-4",
    icon: send,
    title: "Reach",
    content:
      "Maximize your online presence with our top-notch social media marketing services.",
  },
];

export const feedback = [
  {
    id: "feedback-1",
    content:
      "Money is only a tool. It will take you wherever you wish, but it will not replace you as the driver.",
    name: "Herman Jensen",
    title: "Founder & Leader",
    img: people01,
  },
  {
    id: "feedback-2",
    content:
      "Money makes your life easier. If you're lucky to have it, you're lucky.",
    name: "Steve Mark",
    title: "Founder & Leader",
    img: people02,
  },
  {
    id: "feedback-3",
    content:
      "It is usually people in the money business, finance, and international trade that are really rich.",
    name: "Kenn Gallagher",
    title: "Founder & Leader",
    img: people03,
  },
];

export const stats = [
  {
    id: "stats-1",
    title: "User Active",
    value: "3800+",
  },
  {
    id: "stats-2",
    title: "Trusted by Company",
    value: "230+",
  },
  {
    id: "stats-3",
    title: "Transaction",
    value: "$230M+",
  },
];

export const footerLinks = [
  {
    title: "Useful Links",
    links: [
      {
        name: "About us",
        link: "#about",
      },
    ],
  },
  {
    title: "Services",
    links: [
      {
        name: "Web Apps",
        link: "#footer",
      },
      {
        name: "Mobile Apps",
        link: "#footer",
      },
      {
        name: "Social Media",
        link: "#footer",
      },
      {
        name: "SEO",
        link: "#footer",
      },
      {
        name: "Hosting",
        link: "#footer",
      },
    ],
  },
  {
    title: "Partner",
    links: [
      {
        name: "Become a Partner",
        link: "#footer",
      },
    ],
  },
];

export const socialMedia = [
  {
    id: "social-media-1",
    icon: instagram,
    link: "https://www.instagram.com/uscodxb/",
  },
  {
    id: "social-media-2",
    icon: facebook,
    link: "https://www.facebook.com/uscodxb/",
  },
  {
    id: "social-media-3",
    icon: twitter,
    link: "https://www.twitter.com/uscodxb/",
  },
  {
    id: "social-media-4",
    icon: linkedin,
    link: "https://www.linkedin.com/company/uscodubai/?viewAsMember=true",
  },
];

export const clients = [
  {
    id: "client-1",
    logo: airbnb,
  },
  {
    id: "client-2",
    logo: binance,
  },
  {
    id: "client-3",
    logo: coinbase,
  },
  {
    id: "client-4",
    logo: dropbox,
  },
];