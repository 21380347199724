import React, { useEffect } from 'react'
import { online_growth } from "../assets";
import styles, { layout } from "../style";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const SocialServices = () => {
  const imageDivVariants = {
    visible: { opacity: 1, x: 0, transition: { delay:0.2, duration:0.2, stiffness:120, type:"spring" } },
    hidden: { opacity: 0, x: -50 }
  };
  const textDivVariants = {
    visible: { opacity: 1, x: 0, transition: { delay:0.2, duration:0.2, stiffness:120, type:"spring"  } },
    hidden: { opacity: 0, x: 50 }
  };
  const controls = useAnimation();
  const [refImageDiv, inView] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return(
  <section id="marketing" className={layout.sectionReverse}>
    <motion.div 
      ref={refImageDiv}
      animate={controls}
      initial="hidden"
      variants={imageDivVariants}
    className={layout.sectionImgReverse}
    >
      
      <img src={ online_growth } alt="online_growth" className="w-[100%] h-[100%] relative z-[5] grayscale-50" />

      {/* gradient start */}
      <div className="absolute z-[3] -left-1/2 top-0 w-[50%] h-[50%] rounded-full white__gradient" />
      <div className="absolute z-[0] w-[50%] h-[50%] -left-1/2 bottom-0 rounded-full pink__gradient" />
      {/* gradient end */}
    </motion.div>

    <motion.div 
      animate={controls}
      initial="hidden"
      variants={textDivVariants}
      className={layout.sectionInfo}>
      <h2 className={styles.heading2}>
      Maximize your online impact.<br className="sm:block hidden" /> <span  className="text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-purple-500"> Now.</span>
      </h2>
      <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
      From strategy to execution, we've got you covered. Boost engagement, reach new audiences, and drive success - try our services now.
      </p>

    </motion.div>
  </section>
)};

export default SocialServices;
