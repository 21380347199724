import { useState,useEffect } from 'react'
import { Route, Routes } from "react-router-dom"
import styles from "./style";
import {MainContainer, Footer, Navbar} from "./components";

import { firebaseConfig } from "./utils";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
const app = initializeApp(firebaseConfig);

function App() {
  const analytics = getAnalytics(app);
  useEffect(()=> {
    logEvent(analytics,"visited_home");
  },[]);
  return (
  <div className="bg-primary w-full overflow-hidden">
    <div className={`${styles.paddingX} ${styles.flexCenter}`}>
      <div className={`${styles.boxWidth}`}>
        <Navbar />
      </div>
    </div>
    <main className="w-full">
      <Routes>
        <Route path="/*" element={<MainContainer />} />
      </Routes>
    </main>
    <div className={`bg-primary ${styles.paddingX} ${styles.flexCenter}`}>
        <div className={`${styles.boxWidth}`}>
            <Footer />
        </div>
    </div> 
  </div>
  )
}

export default App
