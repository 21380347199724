import React from 'react'
import styles from "../style";
import { SocialServices, Business, Ecommerce, CTA,  Hero } from "../components";
const MainContainer = () => {
    const handleClickScroll = (elementId) => {
        const element = document.getElementById(elementId);
        if (element) {
          // 👇 Will scroll smoothly to the top of the next section
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };
  return (
    <div className="w-full">
        <div className={`bg-primary ${styles.flexStart}`}>
            <div className={`${styles.boxWidth}`}>
                <Hero />
            </div>
        </div>
        
        <div className={`bg-primary ${styles.paddingX} ${styles.flexCenter}`}>
            <div className={`${styles.boxWidth}`}>
                <Business handleClickScroll={ handleClickScroll }/>
                <SocialServices />
                <Ecommerce handleClickScroll={ handleClickScroll } />
                <CTA handleClickScroll={ handleClickScroll } />
            </div>
        </div>        

    </div>
  )
}

export default MainContainer