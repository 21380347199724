import React, { useEffect } from 'react'
import styles from "../style";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

import { Suspense, useState } from "react";
import { MotionConfig, useMotionValue } from "framer-motion";
import { Shapes } from "./Shapes";
import { transition } from "../utils";
import useMeasure from "react-use-measure";

const Hero = () => {

  const [ref, bounds] = useMeasure({ scroll: false });
  const [isHover, setIsHover] = useState(false);
  const [isPress, setIsPress] = useState(false);
  const mouseX = useMotionValue(0);
  const mouseY = useMotionValue(0);

  const resetMousePosition = () => {
    mouseX.set(0);
    mouseY.set(0);
  };

  const imageDivVariants = {
    visible: { opacity: 1, x: 0, transition: { delay:0.2, duration:0.5, stiffness:120, type:"spring" } },
    hidden: { opacity: 0, x: -50 }
  };
  const textDivVariants = {
    visible: { opacity: 1, x: 0, transition: { delay:0.2, duration:0.5, stiffness:120, type:"spring"  } },
    hidden: { opacity: 0, x: 50 }
  };
  const controls = useAnimation();
  const [refImageDiv, inView] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <section id="home" className={`flex md:flex-row flex-col ${styles.paddingY} relative`}>
      
      <motion.div 
        ref={refImageDiv}
        animate={controls}
        initial="hidden"
        variants={textDivVariants}
        className={`flex-1 ${styles.flexStart} flex-col xl:px-0 sm:px-16 px-6`}>
        

        <div className="flex flex-row justify-between items-center w-full">
          <h1 className="flex-1 font-poppins font-semibold ss:text-[60px] text-[42px] text-white ss:leading-[80.8px] leading-[60px]">
            Soar with <br className="sm:block hidden" />{" "}
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-purple-500">cloud solutions:</span>{" "}
          </h1>
        </div>

        <h1 className="font-poppins font-semibold ss:text-[60px] text-[42px] text-white ss:leading-[100.8px] leading-[75px] w-full">
        secure and reliable.
        </h1>
        <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
        Elevate your business to the cloud with our reliable and secure solutions.
        </p>
      </motion.div>
     <motion.div 
       animate={controls}
       initial="hidden"
       variants={imageDivVariants}
        className={`flex-1 flex ${styles.flexCenter} md:my-0 my-10 relative w-full`}>
          <motion.img  initial={{ opacity: 1 }} src="https://dzqanumfl99o0.cloudfront.net/upspin/assets/robot.png" alt="Robot Hand" className="w-[100%] h-[100%] relative z-[5]"></motion.img>
           
        
      </motion.div>
      
    </section>
  );
};

export default Hero;
