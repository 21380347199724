import React, { useEffect } from 'react'
import { analytics } from "../assets";
import styles, { layout } from "../style";
import Button from "./Button";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
const Ecommerce = ({handleClickScroll }) => {
  const imageDivVariants = {
    visible: { opacity: 1, x: 0, transition: { delay:0.2, duration:0.3 } },
    hidden: { opacity: 0.3, x: -50 }
  };
  const textDivVariants = {
    visible: { opacity: 1, x: 0, transition: { delay:0.2, duration:0.3 } },
    hidden: { opacity: 0.3, x: 50 }
  };
  const controls = useAnimation();
  const [refEcommerceDiv, inView] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  
  return(
  <section id="ecommerce" className={layout.section}>
    <motion.div  
      ref={refEcommerceDiv}
      animate={controls}
      initial="hidden"
      variants={textDivVariants}
      className={layout.sectionInfo}>
      <h2 className={styles.heading2}>
      Empower Your Business with an <span  className="text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-purple-500"> Online Store</span><br className="sm:block hidden" />  Now!
      </h2>
      <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
      Expand Your Reach with an Online Store. Increase Sales and Efficiency Today.
      </p>

      <Button styles={`mt-10`} handleClickScroll={ handleClickScroll }/>
    </motion.div>

    <motion.div
      animate={controls}
      initial="hidden"
      variants={imageDivVariants}
      className={layout.sectionImg}>
      <img src={analytics} alt="analytics" className="w-[100%] h-[100%] grayscale-50" />
    </motion.div>
  </section>
)};

export default Ecommerce;
