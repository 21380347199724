import { useState } from "react";

import { close,  menu, logo } from "../assets";
import logo_usco from'../assets/logo_usco.png';
import { navLinks } from "../constants";
import { motion } from "framer-motion"


const Navbar = () => {
  
  const [active, setActive] = useState("Home");
  const [toggle, setToggle] = useState(false);
  return (
  
    <motion.nav 
      initial={{ y: 10 }}
      animate={{ y: 0 }}
      transition = {{ delay:0.3,duration:"0.5", type:"spring", stiffness:"120"}}
      className="w-full flex py-6 justify-between items-center navbar">
          
      
        <img src={logo} alt="upspin" className="w-[60px] h-[60px] bg-gradient-to-r from-blue-500 to-purple-500  border-2 border-gray-200 hover:from-blue-500 hover:to-pink-500" />
      
      <ul className="list-none sm:flex hidden justify-end items-center flex-1">
        {navLinks.map((nav, index) => (
          <li
            key={nav.id}
            className={`font-poppins font-normal cursor-pointer text-[16px] ${
              active === nav.title ? "text-white" : "text-dimWhite"
            } ${index === navLinks.length - 1 ? "mr-0" : "mr-10"}`}
            onClick={() => setActive(nav.title)}
          >
            <a href={`${nav.link}`}>{nav.title}</a>
          </li>
        ))}
      </ul>

      <motion.div className="sm:hidden flex flex-1 justify-end items-center relative  z-[50]"
        >
        <img
          src={toggle ? close : menu}
          alt="menu"
          className="w-[28px] h-[28px] object-contain"
          onClick={() => setToggle(!toggle)}
        />

        <div
          className={`${
            !toggle ? "hidden" : "flex"
          } p-6 bg-black-gradient absolute top-20 right-0 mx-4 my-2 min-w-[140px] rounded-xl sidebar w-full`}
        >
          <ul className="list-none flex justify-end items-start flex-1 flex-col">
            {navLinks.map((nav, index) => (
              <li
                key={nav.id}
                className={`font-poppins font-medium cursor-pointer text-[16px] ${
                  active === nav.title ? "text-white" : "text-dimWhite"
                } ${index === navLinks.length - 1 ? "mb-0" : "mb-4"}`}
                onClick={() => setActive(nav.title)}
              >
                <a href={`${nav.link}`}>{nav.title}</a>
              </li>
            ))}
          </ul>
        </div>
      </motion.div>
    </motion.nav>
            
  );
};

export default Navbar;
