const firebaseConfig = {
  apiKey: "AIzaSyCvMRC2v3pCMy2NZ_osokRzN1mnN5rdpkQ",
  authDomain: "uscoweb-bff5b.firebaseapp.com",
  projectId: "uscoweb-bff5b",
  storageBucket: "uscoweb-bff5b.appspot.com",
  messagingSenderId: "62528504291",
  appId: "1:62528504291:web:4077beb01d846d736860d6",
  measurementId: "G-DT0XXG7NY9"
};

export default firebaseConfig;

